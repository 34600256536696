import { createRouter, createWebHistory } from 'vue-router';
import routes from './routing-table';

const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

export default router;
