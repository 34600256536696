// import site-wide css
import 'animate.css'; // animate effects
// app stuff
import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import VueGtag from 'vue-gtag';
import Router from '@/router';
import Store from '@/store';
import App from '@/App.vue';
import quasarOptions from '@/config/quasar-options';

const RollaSrlApp = createApp(App);

RollaSrlApp
  .use(Quasar, quasarOptions)
  .use(Store)
  .use(Router);

if (process.env.NODE_ENV === 'production') {
  RollaSrlApp.use(
    VueGtag,
    { config: { id: process.env.VUE_APP_GTAG } },
    Router,
  );
}

RollaSrlApp.mount('#app');
