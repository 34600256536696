// App actions object
import Notify from 'quasar/src/plugins/Notify.js';;
import axios from '@/config/axios';

export default {
  // Initialize the store
  appInitState: ({ commit }) => {
    axios({
      method: 'get',
      url: `${process.env.VUE_APP_API_NAMESPACE}/app-init-state`,
    })
      .then((response) => {
        commit('appInitState', response.data);
      })
      .catch((error) => {
        Notify.create({ type: 'app-error', message: error.message });
      });
  },
  // info page
  getInfoPage: ({ state, commit }, info) => new Promise((resolve, reject) => {
    const infoPage = state.infoCache[info];
    if (infoPage) resolve(infoPage);
    axios({
      method: 'get',
      url: `${process.env.VUE_APP_API_NAMESPACE}/get-info-page`,
      params: { info },
    })
      .then((response) => {
        commit('updateInfoCache', { key: info, page: response.data });
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  }),
  // send the contact form
  sendContactForm: ({ state }, form) => new Promise((resolve, reject) => {
    form.destination = state.contacts.to;
    form.subject = form.subject || state.contacts.subject;
    axios({
      method: 'post',
      url: `${process.env.VUE_APP_API_NAMESPACE}/send-contact`,
      data: form,
    })
      .then((response) => { resolve(response.data); })
      .catch((error) => { reject(error.response.data); });
  }),
  // get products - query: { group, slug }
  catalogPage: (context, query) => new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.VUE_APP_API_NAMESPACE}/catalog-page`,
      params: query,
    })
      .then((response) => { resolve(response.data); })
      .catch((error) => { reject(error.response.data); });
  }),
  // get product
  getProduct: (context, slug) => new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.VUE_APP_API_NAMESPACE}/get-product`,
      params: { slug },
    })
      .then((response) => { resolve(response.data); })
      .catch((error) => { reject(error.response.data); });
  }),
};
