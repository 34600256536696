// app state object
export default {
  appLoading: true,
  mainMenu: [],
  footerMenu: [],
  brands: [],
  contacts: {
    to: '',
    subject: '',
  },
  labels: {},
  pIva: 'P. IVA 02598010466',
  infoCache: {},
};
