<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const footerMenu = computed(() => store.state.footerMenu);
const clickedItem = (index) => {
  const menuItem = footerMenu.value[index];
  const routerPath = menuItem.type
    ? `/${menuItem.type}/${menuItem.link}`
    : `/${menuItem.link}`;
  router.push(routerPath);
};
</script>

<template>
  <div
    v-if="footerMenu.length"
    class="footer-menu row justify-end"
  >
    <q-btn-group flat>
      <q-btn
        v-for="(item, index) in footerMenu"
        :key="index"
        :label="item.label"
        flat
        text-color="blue-grey-8"
        @click="clickedItem(index)"
        class="menu-item"
      />
    </q-btn-group>
  </div>
</template>

<style lang="scss">
@import '~quasar-variables';

.footer-menu {
  padding: 0;
  .menu-item {
    @media (max-width: 684px) {
      font-size: .75rem;
    }
    @media (max-width: 414px) {
      font-size: .7rem;
    }
  }
}
</style>
