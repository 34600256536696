<script setup>
/* eslint no-unused-vars: */
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const mainMenu = computed(() => store.state.mainMenu);
</script>

<template lang="html">
  <div class="site-menu">
    <q-item class="menu-header">
      <q-item-section avatar>
        <q-icon
          left
          size="md"
          color="blue-grey-8"
          name="format_paint"
        />
      </q-item-section>
      <q-item-section class="q-pt-sm">
        <p class="drawer-title">
          {{ store.state.labels.drawer_menu }}
        </p>
      </q-item-section>
    </q-item>
    <nav class="nav-tree">
      <q-tree
        :nodes="mainMenu"
        node-key="id"
      >
        <template v-slot:default-header="prop">
          <div class="nav-item-container">
            <router-link
              v-if="!prop.node.children"
              :to="`${prop.node.path}/${prop.node.link}`"
              class="nav-menu-item link-item"
            >
              <span class="menu-item-label">{{ prop.node.label }}</span>
            </router-link>
            <div
              v-else
              class="nav-menu-item"
            >
              <span class="menu-item-label">{{ prop.node.label }}</span>
            </div>
          </div>
        </template>
      </q-tree>
    </nav>
  </div>
</template>

<style lang="scss">
@import '~quasar-variables';

.site-menu {
  .nav-tree .nav-item-container {
    width: 100%;
    .nav-menu-item {
      cursor: pointer;
      color: $blue-grey-8;
      .menu-item-label { font-size: 1.2rem; letter-spacing: .1rem; }
      &.link-item {
        padding: .3rem .2rem;
        transition: all .2s;
        &:hover { background-color: $menu-hover; }
      }
    }
    a.nav-menu-item {
      display: inline-block;
      width: 100%;
      text-decoration: none;
      &.router-link-exact-active { color: $info; }
    }
  }
}
</style>
