// app mutations object
export default {
  // Initial state setting
  appInitState: (state, payload) => {
    state.mainMenu = payload.main_menu;
    state.footerMenu = payload.footer_menu;
    state.brands = payload.brands;
    state.contacts = payload.contacts;
    state.labels = payload.labels;
    state.appLoading = false;
  },
  // Update Info pages cache
  updateInfoCache: (state, payload) => {
    const { key, page } = payload;
    state.infoCache[key] = page;
  },
};
