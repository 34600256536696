import SiteLayout from '@/components/layouts/site-layout';

export default [
  {
    path: '/',
    component: SiteLayout,
    children: [
      // Home page
      {
        path: '',
        name: 'HomePage',
        component: () => import('@/components/home/home-page'),
      },
      // Catalog page - show products grouped for: Category or Brand
      {
        path: '/catalog/:group(category|brand)/:slug',
        name: 'CatalogPage',
        component: () => import('@/components/catalog/catalog-page'),
      },
      // Product page - show product page
      {
        path: '/product/:slug',
        name: 'ProductPage',
        component: () => import('@/components/product/product-page'),
      },
      // Info pages - About, Terms and Conditions, Privacy, Shipping
      {
        path: '/info-page/:info(about|terms-and-conditions|privacy-policy|cookie-policy|shipping)',
        name: 'InfoPage',
        component: () => import('@/components/info/info-page'),
      },
      // contacts page
      {
        path: '/contacts',
        name: 'ContactsPage',
        component: () => import('@/components/contacts/contacts-page'),
      },
      // Not Found page
      {
        path: '*',
        name: '404Page',
        component: () => import('@/components/404/404-page'),
      },
    ],
  },
];
