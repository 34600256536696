<script setup>
import { ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { Notify } from 'quasar';
import { required, email } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const store = useStore();

const contact = ref({
  name: '',
  email: '',
  subject: '',
  message: '',
});
const sending = ref(false);
const rules = {
  email: { required, email },
  message: { required },
};
const v$ = useVuelidate(rules, contact);
const resetForm = () => {
  contact.value.name = '';
  contact.value.email = '';
  contact.value.subject = '';
  contact.value.message = '';
  nextTick(() => { v$.value.$reset(); });
};
const sendForm = () => {
  sending.value = true;
  store.dispatch('sendContactForm', contact.value)
    .then((sent) => {
      if (sent) {
        Notify.create({ type: 'app-done', message: 'Messaggio inviato con successo.' });
        resetForm();
      } else {
        Notify.create({ type: 'app-error', message: 'Errore. Messaggio non inviato.' });
      }
      sending.value = false;
    })
    .catch((error) => {
      Notify.create({ type: 'app-error', message: error.message });
      sending.value = false;
    });
};
</script>

<template lang="html">
  <div class="contact-form q-pa-sm">
    <q-input
      v-model="contact.name"
      label="nome"
      class="field"
    >
      <template v-slot:prepend>
        <q-icon name="person"/>
      </template>
    </q-input>
    <q-input
      v-model="contact.email"
      label="la tua email"
      :error="v$.email.$error"
      error-message="indirizzo email non valido"
      @blur="v$.email.$touch()"
      class="required field"
    >
      <template v-slot:prepend>
        <q-icon name="alternate_email"/>
      </template>
    </q-input>
    <q-input
      v-model="contact.subject"
      label="oggetto"
      class="field"
    >
      <template v-slot:prepend>
        <q-icon name="mail_outline"/>
      </template>
    </q-input>
    <q-input
      v-model="contact.message"
      type="textarea"
      label="il tuo messaggio"
      :error="v$.message.$error"
      error-message="messaggio vuoto"
      @blur="v$.message.$touch()"
      class="required field"
    />
    <div class="form-actions">
      <q-btn
        label="invia"
        icon="send"
        color="blue-grey-8"
        :loading="sending"
        :disable="v$.$invalid"
        @click="sendForm"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import '~quasar-variables';

.contact-form {
  max-width: 750px;
  .form-actions {
    text-align: right;
    .reset-btn { margin-right: 1rem; }
  }
}
</style>
