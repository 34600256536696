<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import LoadingView from '@/components/layouts/loading-view';

const store = useStore();

const loading = computed(() => store.state.appLoading);
const loadSettings = {
  loadingMessage: 'init Rolla app...',
  height: '100vh',
};
// load init app state
store.dispatch('appInitState');
</script>

<template lang="html">
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition-group
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated is-leaving animate__fadeOut"
      >
        <LoadingView
          v-if="loading"
          key="loading-view"
          :args="loadSettings"
        />
        <component
          v-else
          :is="Component"
          key="router-view"
        />
      </transition-group>
    </router-view>
  </div>
</template>

<style lang="scss">
@import '~quasar-variables';

#app { position: relative; }
</style>
