<script setup>
import { computed } from 'vue';

const props = defineProps({
  args: {
    type: Object,
    default: () => ({}),
  },
});

const defaultSettings = {
  height: '90vh',
  spinnerSize: '56px',
  spinnerColor: 'blue-grey-1',
  loadingMessage: 'loading...',
  messageSize: 'medium',
  messageColor: 'blue-grey-4',
};
const settings = computed(() => (
  Object.assign(defaultSettings, props.args)
));
</script>

<template>
  <div
    :style="`height: ${settings.height};`"
    class="loading-data flex flex-center"
  >
    <q-inner-loading :showing="true">
      <slot name="spinner">
        <q-spinner-bars
          :size="`${settings.spinnerSize}`"
          :color="`${settings.spinnerColor}`"
        ></q-spinner-bars>
      </slot>
      <p
        class="message"
        :class="[settings.messageSize, `text-${settings.messageColor}`]"
      >
        {{ settings.loadingMessage }}
      </p>
    </q-inner-loading>
  </div>
</template>

<style lang="scss">
$font-sizes: ('large': 1.5rem, 'medium': 1.2rem, 'small': .8rem);

.loading-data {
  width: 100%;
  .message {
    margin-top: 2rem;
    color: gray;
    @each $key, $value in $font-sizes {
      &.#{$key} { font-size: $value; }
    }
  }
}
</style>
