<script setup>
import { ref, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import eventHub from '@/config/event-hub';
import SiteMenu from '@/components/widgets/site-menu';
import SiteFooter from '@/components/widgets/site-footer';
import ContactForm from '@/components/widgets/contact-form';

const store = useStore();
const q$ = useQuasar();

const rightDrawer = ref(false);
const maxDrawerWidth = 400;
const drawerWidth = computed(() => (
  Math.min(maxDrawerWidth, q$.screen.width)
));
const toggleDrawer = () => { rightDrawer.value = !rightDrawer.value; };
eventHub.$on('open-menu', toggleDrawer);
onBeforeUnmount(() => {
  eventHub.$off('open-menu', toggleDrawer);
});
</script>

<template lang="html">
  <q-layout
    view="lHr LpR lfr"
    class="site-layout"
  >
    <q-drawer
      v-model="rightDrawer"
      bordered
      side="right"
      behavior="mobile"
      :width="drawerWidth"
    >
      <div class="right-drawer q-pa-sm" style="min-height: 120vh">
        <q-btn
          icon="close"
          fab
          flat
          text-color="blue-grey-8"
          @click="rightDrawer = !rightDrawer"
        />
        <q-expansion-item popup>
          <template #header>
            <q-item-section avatar>
              <q-icon
                left
                size="md"
                color="logo-red"
                name="alternate_email"
              />
            </q-item-section>
            <q-item-section class="q-pt-sm">
              <p class="drawer-title">
                {{ store.state.labels.drawer_contact }}
              </p>
            </q-item-section>
          </template>
          <ContactForm />
        </q-expansion-item>
        <SiteMenu class="q-mt-md"/>
      </div>
    </q-drawer>
    <router-view />
    <SiteFooter />
  </q-layout>
</template>

<style lang="scss">
@import '~quasar-variables';

.right-drawer {
  position: relative;
  .close-drawer {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
