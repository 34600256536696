import { createStore } from 'vuex';
import settings from './settings';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default createStore({
  state: { ...settings, ...state },
  getters: { ...getters },
  mutations: { ...mutations },
  actions: { ...actions },
  modules: {},
});
