<script setup>
/* eslint import/no-unresolved: */
import { useStore } from 'vuex';
import FooterMenu from './footer-menu';

const store = useStore();
</script>

<template lang="html">
  <q-footer class="footer-layout">
    <q-separator />
    <div class="footer-content q-pa-md">
      <div class="company">
        <img alt="" src="@/assets/images/logo.png" />
        <p class="company-vat">{{ store.state.pIva }}</p>
      </div>
      <FooterMenu class="items-end"/>
    </div>
  </q-footer>
</template>

<style lang="scss">
@import '~quasar-variables';

.footer-layout {
  background-color: white !important;
  .footer-content {
    max-width: $page-max-width;
    margin: auto;
    display: grid;
    @media (min-width: 750px) { grid-template-columns: 1fr 2fr; }
    .company {
      .company-vat {
        font-size: 1.1rem;
        letter-spacing: .5px;
        color: $blue-grey-9;
        margin: 0 0 0 5px;
      }
      img { max-width: 250px; height: auto; }
    }
  }
}
</style>
