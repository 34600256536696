import Notify from 'quasar/src/plugins/Notify.js';;
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@/styles/quasar.scss';

// register custom notifications type
Notify.registerType('app-warn', {
  icon: 'help_outline',
  color: 'warning',
});

Notify.registerType('app-done', {
  icon: 'task_alt',
  color: 'positive',
});

Notify.registerType('app-error', {
  icon: 'error_outline',
  color: 'negative',
});

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    notify: {
      position: 'center',
      caption: 'Rolla srl',
      textColor: 'white',
      actions: [{ label: 'dismiss', color: 'white' }],
    },
  },
  plugins: {
    Notify,
  },
};
