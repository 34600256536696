import script from "./loading-view.vue?vue&type=script&setup=true&lang=js"
export * from "./loading-view.vue?vue&type=script&setup=true&lang=js"

import "./loading-view.vue?vue&type=style&index=0&id=36608318&lang=scss"

const __exports__ = script;

export default __exports__
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerBars from 'quasar/src/components/spinner/QSpinnerBars.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInnerLoading,QSpinnerBars});
